import React, {useEffect, useRef, useState} from 'react'
import {SidePane} from "../../../../../Common/fSidePane/RightPane";
import Invoice from "./Invoice";
import {Spinner} from "../../../../../Common/fLoader";
import {DatePickerCustom, FunctionalInput} from "../../../../../Common/fInputs";
import {FileUpload} from "../../../../../Common/fInputs/FunctionalFile";
import PhotoTile from "../../../../../Common/fPhoto";
import {handleError, showToast} from "../../../../../Common/fToast/ToastCallback";
import Moment from "moment";
import {SearchOffice2} from "../../../../../Common/fSubmitInvoice/common/SearchOffice";
import {Modal} from "../../../../../Common/fModal/Modal";
import {Select} from "../../../../../Common/Class/Select";
import {FunctionalKeyValue} from "../../../../../Common/fInputs/details";
import {get, post} from '../../../../../../Model/Network/Config/Axios';
import {SearchVendorAddress} from '../../../../../Common/fSubmitInvoice/common/SearchVendorAddress';
import ProductSearch from '../../ProductSearch';
import useDateFormat from '../../../useDateFormat';
import { Form } from '../../../../../Common/Class/Form';
import { StateButton } from '../../../../../Common/fStateButton';
import { ReadOnly } from '../../../../../Common/fCard/ButtonOutline/ReadOnly';
import SelectGST from '../../../../../Common/fUtils/SelectGST';
import { useSelector } from 'react-redux';

export default function SubmitInvoice(props) {
	let {data,lineItemEditDisable} = props;
	if (!data) data = {}
  const [gst, setGst] = useState({
      value: 5,
      label: 5
  })

  let vendor = props.vendor;
	if (props.poId){
		vendor = {
			...data.vendor,
			gstin:data.vendor.address.gstin?data.vendor.address.gstin:data.vendor.gstin
		}
	}
  const TAX=useRef();
	let [modal, setModal] = useState(false)
	const [loading, setLoading] = useState(true);
	const [files, setFiles] = useState([]);
	const [discount, setDiscount] = useState(0);
	const [singleService, setSingleService] = useState({});
	const [services, setServices] = useState([]);
	const [inputData, setInputData] = useState({
		vendor: vendor,
		billingTo: data.billingTo,
		shippingTo: data.shipTo,
		lineItems: props.lineItems?props.lineItems: [],
		billDate: undefined,
		due: undefined,
		billNumber: undefined,
		selfAddress: undefined,
		taxAmt: 0
	});
	const [btnLoading, setBtnLoading] = useState(false);
  const [contractTypeWiseAmountEdit,setContractTypeWiseAmountEdit]=useState({
    key:"",
    value:""
  });
  let propData=((props && props.data && props.data.contractData && props.data.contractData.type)?props.data.contractData.type:undefined);
  const [contractType,] = useState(propData);
  const [edit, setEdit] = useState(false);
  const [editGst,setEditGst] = useState({
		value:2,
		label:2
	})
  let {searchProductByService} = useSelector(state=>state.auth.config.plugins)

	let NAME = useRef(), QTTY = useRef(), PRICE = useRef(), MATERIAL = useRef(),FORM1=useRef(), EDITQTTY = useRef(), EDITPRICE = useRef();
 let dateFormate = useDateFormat('DD-MM-YYYY')
	useEffect(() => {
		get(`vendor/config/${props.categoryId}/services`, (e, r) => {
			if (r) {
				setLoading(false);
				setServices(r.items.map(i => {
					return {
						value: i.id,
						label: i.name
					}
				}))
				if (r.items.length > 0) {
					setSingleService({
						label: r.items[0].name,
						value: r.items[0].id
					})
				}

			}
		})

	}, [])

  useEffect(() => {
    if (edit) {
      getDetailsEdit()
    }
  }, [edit, props.poId])

  const getDetailsEdit = async () => {
    EDITQTTY.current.value = edit.qtty;
    await getFormDate()
    FORM1.current.loadForm(`invoice::item::${props.categoryId}`);
    if (!props.poId) {
      EDITPRICE.current.value = edit.price;
      setEditGst({
        value: edit.gst,
        label: edit.gst
      })
    }
  }

  const getFormDate = async () => {
    get(`/forms/invoice::item::${props.categoryId}`, (e, r) => {
      if (r) {
        if (r.forms.length > 0) {
          let forms = {};
          r.forms.forEach((item) => {
            if (edit.forms && edit.forms[item.id]) {
              forms[item.fieldId] = edit.forms[item.id];
            }
          })
          if (Object.keys(forms).length > 0) {
            edit.forms = forms
          };
        }
      }else{
        handleError(e)
      }
    })
  }

  useEffect(()=>{
		if(modal) {
		  FORM1.current.loadForm(`invoice::item::${props.categoryId}`)
		}

	},[modal,props.categoryId])

  useEffect(()=>{
    INIT();
  },[])

  const INIT = () => {
    get(`settings/exceptions/vendor`, (e,r)=>{
        if(r){
          let obj={};
          Object.entries(r.contractTypeWiseAmountEdit).forEach(([key, value]) => {
            obj['key']=key;
            obj['value']=value;
          })
          setContractTypeWiseAmountEdit(obj);
        }
    })
  }

	if (loading) {
		return <SidePane column={<Spinner/>} button={false} onClose={() => props.onClose(false)}>
			<Spinner/>
		</SidePane>
	}

	let base = 0, tax = 0;

	inputData.lineItems.forEach(item => {
		base = base + parseFloat(item.base);
    tax += (item && item.tax)?item.tax:0;
	})

	let discountAmount = base * (discount/100);
	let total = base-discountAmount;

	return (
    <div>
      <SidePane
        button={false}
        onClose={() => props.onClose(false)}
        column={
          <div>
            <Invoice
              taxAmt={tax}
              discount={discount}
              vendor={vendor}
              data={inputData}
            />
          </div>
        }
      >
        <div
          className="FadeRight-Effect"
          style={{ fontSize: "12px", width: "100%" }}
        >
          <div
            style={{
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "1.3",
              marginBottom: 30,
            }}
          >
            Submit Invoice
          </div>
          <div id="Invoice_Format" className="FadeRight-Effect">
            {vendor ? (
              <div />
            ) : (
              <div>
                <SearchOffice2
                  entityId={(props && props.entityId)?props.entityId:undefined}
                  title="Bill To"
                  office={inputData.billingTo}
                  onSelect={(billingTo) => {
                    setInputData({
                      ...inputData,
                      billingTo: {
                        ...billingTo,
                        name: billingTo.label,
                      },
                    });
                  }}
                  p2p={true}
                />
                <SearchOffice2
                  entityId={(props && props.entityId)?props.entityId:undefined}
                  office={inputData.shippingTo}
                  title="Ship To"
                  onSelect={(shippingTo) => {
                    setInputData({
                      ...inputData,
                      shippingTo: {
                        ...shippingTo,
                        name: shippingTo.label,
                      },
                    });
                  }}
                  p2p={true}
                />
              </div>
            )}
            {props.poId ? (
              <SearchVendorAddress
                title="Search Vendor Address"
                onSelect={(selfAddress) => {
                  setInputData({
                    ...inputData,
                    selfAddress: {
                      ...selfAddress,
                      name: selfAddress.name,
                    },
                  });
                }}
                selfAddress={inputData.selfAddress}
                id={props.vendorId}
              />
            ) : null}
            <div style={{ display: "flex" }}>
              <FunctionalInput
                type="text"
                title="Bill Number"
                ref={() => {}}
                onChange={(e) => {
                  let billNumber = e.target.value;
                  setInputData({
                    ...inputData,
                    billNumber,
                  });
                }}
              />
            </div>
            <div style={{ display: "flex" }}>
              <DatePickerCustom
                ref={() => {}}
                label={"Bill Date"}
                formate={dateFormate}
                onChange={(date) => {
				let billDate = Moment(date).format("YYYY-MM-DD");
                  setInputData({
                    ...inputData,
                    billDate,
                  });
                }}
              />
              <div style={{ marginLeft: 12, width:'100%' }}>
			   <DatePickerCustom
                ref={() => {}}
                label={"Due Date"}
                formate={dateFormate}
                onChange={(date) => {
				let due = Moment(date).format("YYYY-MM-DD");
                  setInputData({
                    ...inputData,
                    due,
                  });
                }}
              />
              </div>
            </div>

            <p
              style={{
                color: "var(--text-color)",
                fontSize: 13,
                fontWeight: 400,
                marginTop: 12,
              }}
            >
              Line Items
            </p>
            <div>
              <table
                style={{
                  border: "1px solid var(--border-color)",
                  width: "100%",
                  borderCollapse: "collapse",
                  marginTop: "20px",
                }}
              >
                <tr
                  style={{
                    background: "var(--tile-color)",
                    textAlign: "left",
                    borderBottom: "1px solid var(--border-color)",
                  }}
                >
                  <th style={{ padding: "10px" }}>Name</th>
                  <th style={{ padding: "10px" }}>Qty</th>
                  <th style={{ padding: "10px" }}>Price</th>
                  <th style={{ padding: "10px" }}>Tax</th>
                  <th style={{ padding: "10px" }}>Actions</th>
                </tr>
                {inputData.lineItems && inputData.lineItems.length ? (
                  inputData.lineItems.map((row) => {
                    return (
                      <tr>
                        <td style={{ padding: "10px" }}>
                          {row.name}
                          <br />
                          HSN: {row.hsn}
                        </td>
                        <td style={{ padding: "10px" }}>{row.qtty}</td>
                        <td style={{ padding: "10px" }}>{row.price}</td>
                        <td style={{ padding: "10px" }}>{row.gst}</td>
                        <td style={{ padding: "10px" }}>
                          <div
                            style={{
                              color: "#6C5DD3",
                              fontSize: 10,
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setEdit(row);
                            }}
                          >
                            {(contractType && contractType === "RENTAL") ? (contractTypeWiseAmountEdit && contractTypeWiseAmountEdit.key !== "RENTAL") ? "EDIT" : (contractTypeWiseAmountEdit && contractTypeWiseAmountEdit.key === "RENTAL" && contractTypeWiseAmountEdit.value === true) ? "EDIT" : "" : "EDIT"}
                          </div>
                          <div
                            style={{
                              flex: 1,
                              color: "#dc3545",
                              fontSize: 10,
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              let items = inputData.lineItems;
                              items = items.filter(
                                (val) => val.key !== row.key
                              );
                              setInputData({
                                ...inputData,
                                lineItems: items,
                              });
                            }}
                          >
                            {(contractType && contractType==="RENTAL")?(contractTypeWiseAmountEdit && contractTypeWiseAmountEdit.key!=="RENTAL")?"DELETE":(contractTypeWiseAmountEdit && contractTypeWiseAmountEdit.key==="RENTAL" && contractTypeWiseAmountEdit.value===true)?"DELETE":"":"DELETE"}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5} style={{ padding: "10px" }}>
                      No Items added
                    </td>
                  </tr>
                )}
              </table>
            </div>

            <p
              style={{
                marginTop: 12,
                color: "var(--primary-color)",
                fontSize: 13,
                cursor: "pointer",
                fontWeight: 600,
              }}
              onClick={() => {
                setModal(true);
              }}
            >
              + Add Line Item
            </p>

            <div
              style={{ display: "flex", marginTop: 5, marginBottom: "15px" }}
            >
              <FunctionalKeyValue
                title="Base Amount"
                value={`${props.currency} ${base}`}
              />
            </div>
            <FunctionalInput
              blue="Discount will be applied before Tax"
              style={{ marginTop: 12 }}
              onChange={(e) => {
                if (parseFloat(e.target.value)) {
                  let value = parseFloat(e.target.value);
                  if (value > 90) {
                    e.target.value = discount ? discount : "";
                    return;
                  }
                  setDiscount(parseFloat(e.target.value));
                } else {
                  let value = parseFloat(e.target.value);
                  if (!value) {
                    e.target.value = value;
                    setDiscount(0);
                  } else {
                    e.target.value = discount ? discount : "";
                  }
                }
              }}
              title="Discount Percent"
              type="number"
              ref={() => {}}
            />

            <FunctionalKeyValue
              title="Discount Amount"
              style={{ marginTop: 12 }}
              value={`${props.currency} ${discountAmount}`}
            />
            <FunctionalInput
              disabled={true}
              defaultValue={tax?tax:0}
              type="number"
              style={{ marginTop: 12 }}
              title="Tax Amount"
              ref={TAX}
              // onChange={(e) => {
              //   let taxAmt = e.target.value;
              //   if (!taxAmt) taxAmt = 0;
              //   setInputData({
              //     ...inputData,
              //     taxAmt,
              //   });
              // }}
            />
            <FunctionalKeyValue
              title="Total Amount"
              value={`${props.currency} ${parseInt(total) +
                parseInt(tax)}`}
            />

            <div
              style={{
                fontSize: "14px",
                fontWeight: "bold",
                margin: "30px 0px 15px 0px",
              }}
            >
              Attachment
            </div>
            <div
              style={{
                display: "flex",
                marginLeft: "-15px",
                flexWrap: "wrap",
                maxHeight: "250px",
                overflowY: "scroll",
              }}
            >
            <ReadOnly>
              <FileUpload
                onUpload={({ file }) => {
                  setFiles((prev) => [...prev, file]);
                }}
              />
            </ReadOnly>
              {files.length > 0 ? (
                files.map((item, index) => {
                  return <PhotoTile key={`Photo-${index}`} link={item} />;
                })
              ) : (
                <></>
              )}
            </div>
            {btnLoading ?
					   <div
             className="btn btn-portlet"
             style={{ maxWidth: "100%", marginTop: 20 }}>
              <StateButton />
             </div>
              :
            <div
              className="btn btn-portlet"
              style={{
                maxWidth: "100%",
                marginTop: 20,
              }}
              onClick={() => {
                let {
                  billDate,
                  due,
                  billNumber,
                  shippingTo,
                  billingTo,
                  lineItems,
                  selfAddress,
                } = inputData;
                let { vendor } = props;
                if (lineItems.length === 0) {
                  showToast({
                    type: "error",
                    message: "Please select atleast 1 line item",
                  });
                  return;
                }
                if (!props.poId) {
                  if (!vendor) {
                    showToast({
                      type: "error",
                      message: "Please select the vendor",
                    });
                    return;
                  }
                  if (!shippingTo) {
                    showToast({
                      type: "error",
                      message: "Please select the ship to address",
                    });
                    return;
                  }

                  if (!billingTo) {
                    showToast({
                      type: "error",
                      message: "Please select the bill to address ",
                    });
                    return;
                  }
                }
                if (!billDate) {
                  showToast({
                    type: "error",
                    message: "Please enter the bill date",
                  });
                  return;
                }

                if (!billNumber) {
                  showToast({
                    type: "error",
                    message: "Please enter the bill Number",
                  });
                  return;
                }
                // if (!taxAmt) {
                //   showToast({
                //     type: "error",
                //     message: "Please enter Tax Amount",
                //   });
                //   return;
                // }

                if (billDate) {
                  billDate = Moment(billDate, "YYYY-MM-DD");
                  billDate = Moment(billDate).valueOf();
                }

                if (due) {
                  due = Moment(due, "YYYY-MM-DD");
                  due = Moment(due).valueOf();
                }

                if(!files.length){
                  showToast({
                    type: 'error',
                    message: "Attachment is required"
                  })
                  return;
                }
                lineItems = lineItems.map((e) => ({...e,total: e.price * e.qtty}))
                if (props.poId) {
                  if (!selfAddress) {
                    showToast({
                      type: "error",
                      message: "Please select the vendor address",
                    });
                    return;
                  }

                  let data = {
                    currency: props.currency,
                    tax: tax,
                    billDate,
                    due,
                    billNumber,
                    attachment: files,
                    lineItems,
                    poId: +props.poId,
                    discount,
                    addressId: selfAddress.key,
                    categoryId: props.categoryId,
                  };
                  if (props.superId) {
                    data.superId = props.superId;
                  }
                  setBtnLoading(true)
                  post(`vendor/submit/invoice/po`, { data }, (e, r) => {
                    if (r) {
                      showToast({
                        type: "success",
                        message: "Invoice uploaded successfull",
                      });
                      props.onClose(false);
                      props.init();
                    } else {
                      handleError(e);
                    }
                    setBtnLoading(false)
                  });
                } else {
                  let data = {
                    currency: props.currency,
                    tax: tax,
                    billDate,
                    due,
                    billNumber,
                    billingTo,
                    shippingTo,
                    vendor,
                    attachment: files,
                    lineItems,
                    discount,
                    addressId: vendor.key,
                  };
                  data.categoryId = props.categoryId;
                  if (props.superId) {
                    data.superId = props.superId;
                  }
                  setBtnLoading(true)
                  post(`vendor/submit/invoice`, { data }, (e, r) => {
                    if (r) {
                      showToast({
                        type: "success",
                        message: "Invoice uploaded successfull",
                      });
                      props.onClose(false);
                      props.init();
                    } else {
                      handleError(e);
                    }
                    setBtnLoading(false)
                  });
                }
              }}
            >
              Upload
            </div>
            }
          </div>
        </div>
      </SidePane>

      {modal ? (
        <Modal
          show={true}
          des="Add line item details to add one"
          title={"Add Line Item"}
          style={{
            save: {
              background: "var(--primary-color)",
              border: "2px solid var(--primary-color)",
              padding: "8px 15px",
              width: "100%",
              textAlign: "center",
            },
          }}
          close={() => {
            setModal(false);
          }}
          onSave={() => {
            let product = NAME.current.getValue();
            let hsn = MATERIAL.current.value;
            let qtty = +QTTY.current.value;
            let price = +PRICE.current.value;

            if (!product || !product.title) {
              showToast({
                type: "error",
                message: "Please enter Name",
              });
              return;
            }
            if (!qtty) {
              showToast({
                type: "error",
                message: "Please enter Quantity",
              });
              return;
            }
            if (!hsn) {
              showToast({
                type: "error",
                message: "Please enter Material code",
              });
              return;
            }
            if (!price) {
              showToast({
                type: "error",
                message: "Please enter the price",
              });
              return;
            }
            if (price <= 0) {
              showToast({
                type: "error",
                message: "Please enter the valid price",
              });
              return;
            }
            FORM1.current.getKeyValue((cb) => {
            let item = {
              name:product.title,
              qtty,
              price,
              hsn,
              base: price * qtty,
              service: singleService,
              key: `${singleService.value}||${product.title}`,
              forms:cb,
              gst:gst.value,
              tax:(gst.value)?Number(((qtty*price)*(gst.value/100)).toFixed(2)):0
            };
            if(product.id){
              item['productId'] = product.id;
            }
            inputData.lineItems.push(item);
            setInputData(inputData);
            setModal(false);
           })
          }}
          buttonName="Add Line Item"
        >
          <Select
            value={singleService}
            items={services}
            placeholder="Select Service"
            select={setSingleService}
          />
          <ProductSearch placeholder={"Name"} services={searchProductByService && singleService && singleService.label}  ref={NAME} />
          <FunctionalInput
            type="text"
            title="Material Code (HSN/SAC)"
            ref={MATERIAL}
          />
          <FunctionalInput type="number" title="Qtty" ref={QTTY} />
          <FunctionalInput type="number" title="Price per unit" ref={PRICE} />
          <SelectGST defaultValue={gst} placeholder="Select Gst" onSelect={setGst}/>
          <Form
            label="Invoice Line Item Form"
            desc="All field related to vendor form"
            handler={ref => {
             	FORM1.current=ref
        	   }
          } />
        </Modal>
      ) : (
        undefined
      )}
      {edit ? (
        <Modal
          show={true}
          des="Edit line item quantity"
          title={"Edit Line Item"}
          style={{
            save: {
              background: "var(--primary-color)",
              border: "2px solid var(--primary-color)",
              padding: "8px 15px",
              width: "100%",
              color: "white",
              curor: "pointer",
              textAlign: "center",
            },
          }}
          buttonName="Update"
          close={() => {
            setEdit(false);
          }}
          onSave={() => {
            let qtty = parseFloat(EDITQTTY.current.value).toFixed(3);
            let price = props.poId ? edit.price : EDITPRICE.current.value;
            let taxN = props.poId ? edit.taxSlab : editGst.value;

            if (!qtty) {
              showToast({
                type: "error",
                message: "Please enter the quantity",
              });
              return;
            }
            if (!Number(qtty) > 0) {
              showToast({
                type: 'error',
                message: "Please add Quantity"
              })
              return
            }
            if (props.poId) {
              let orignalQtty = props.lineItems.find((e) => e.itemId === edit.itemId)
              if (Number(qtty) > Number(orignalQtty.qtty)) {
                showToast({
                  type: 'error',
                  message: "Quantity should not be more then specified in the line item"
                })
                return
              }
            }
            if (!price) {
              showToast({
                type: "error",
                message: "Please enter the price per unit",
              });
              return;
            }

            let discountSlab = 0;
            let base = price * +qtty;
            let grossBase = base - base * (discountSlab / 100);
            let tax = grossBase * (taxN / 100);

            let item = {
              ...edit
            };
            FORM1.current.getKeyValue((cb) => {
              item = { ...edit };
              item.qtty = +qtty;
              item.base = base;
              item.taxSlab = taxN;
              item.gst = taxN;
              item.price = +price;
              item.grossBase = grossBase;
              item.tax = tax;
              item.total = tax + grossBase;
              item.forms = cb

              const updatedLineItems = inputData.lineItems.map((lineItem) => lineItem.key === item.key ? item : lineItem);
              setInputData({
                ...inputData,
                lineItems: updatedLineItems,
              });
              setEdit(false);
            })
          }}
        >
          <FunctionalInput
            ref={EDITQTTY}
            title="Enter Quantity"
            type="number"
            disabled={lineItemEditDisable}

          />
          {
            props.poId ?
              <>
              </>
              :
              <>
                <FunctionalInput
                  ref={EDITPRICE}
                  title="Enter Price Per Unit"
                  disabled={lineItemEditDisable}
                  type="number" />
                <SelectGST defaultValue={editGst} disabled={lineItemEditDisable} placeholder="Select Gst" onSelect={setEditGst} />
              </>
          }
          <Form
            label="Invoice Line Item Form"
            desc="All field related to vendor form"
            preFill={edit.forms}
            handler={ref => {
              FORM1.current = ref
            }
            } />
        </Modal>
      ) : null}
    </div>
  );
}
