import React, { useState } from 'react';
import './fInvoice.css'
import moment from 'moment';
import { numberWithCommas } from '../../../../../Utils/NumberToComma';
import _ from 'lodash'
import { post } from '../../../../../Model/Network/Config/Axios';
import { handleError, showToast } from '../../../../Common/fToast/ToastCallback';
import { useSelector } from 'react-redux';
import LineItemModal from './LineItemModal';
import { Modal } from '../../../../Common/fModal/Modal';
import AddLineItem from './AddLineItem';

function Invoice(props) {
    let { invoiceStatus, enableLineItemPlugin ,systemInvoiceId,enableAddLineItem} = props.data;
    const {disableUpdate,status} = props;
    let {searchProductByService,tdsAtLineItemAutomation,onlyGlobalTdsMaster} = useSelector(state=>state.auth.config.plugins)
    const {tenant} = useSelector(state=>state.auth.config)
    const [showCustomFormId, setShowCustomFormId] = useState(false);
    const [showServiceCustomFormId, setShowServiceCustomFormId] = useState(false);
    const [edit, setEdit] = useState(undefined)
    const [remove, setRemove] = useState(undefined)
        const [addItem, setAddItem] = useState(false)
    

    // const rednerTable = (item, index) => {
    //     return (
    //         <div key={`entry-${index}`} style={{display: 'flex', justifyContent: 'space-evenly', margin: '15px 0px'}}>
    //             <div style={{width: '160px'}}>{item.desc}<br/>Code: {item.hsn}</div>
    //             <div style={{width: '100px',}}> {(item.price).toFixed(2)}</div>
    //             <div style={{width: '100px',}}>{item.qtty}</div>
    //             <div style={{width: '100px', textAlign: 'right'}}>{item.amount?item.amount.toFixed(2):null}</div>
    //         </div>
    //     )
    // }

    const onSaveItem = (payload) => {
        if(onlyGlobalTdsMaster && tdsAtLineItemAutomation && (tenant==="UDAAN" || tenant==="UDAANTEST" || tenant==="INDIAN" || tenant==="KARANDEVENV") && payload.tds){
            const { id } = payload.tds;
            delete payload.tds;
            payload["tds_master"] = id
        }
        else{
            if (payload.tds) {
                const { tdsSlab, tdsCode, tdsDesc } = payload.tds;
                delete payload.tds;
                payload.tdsSlab = tdsSlab;
                payload.tdsCode = tdsCode;
                payload.tdsDesc = tdsDesc;
            }
        }
        post(`vendor/invoicing/${systemInvoiceId}/invoiceItem.update`,
            { ...payload },
            (e, r) => {
                if (r) {
                    props.onReload && props.onReload()
                    showToast({
                        type: 'success',
                        message: 'Line Item Updated Successfully'
                    })
                } else if (e) {
                    handleError(e)
                }
                setEdit(false)
            })
    }


    const onAddLineItem = (payload) =>{
       post(`vendor/invoicing/${systemInvoiceId}/invoiceItem.add`,
       {data:payload},
       (e,r) =>{
        if(r) {
            props.onReload();
            post(`vendor/costAllocation/removeAllocation`,{invoiceId:systemInvoiceId},(e,r)=>{
                if(r) {
                    props.onReload()
                } else {
                    props.onReload()
                }
            })
            showToast({
                type: 'success',
                message:'Line Item Added Successfully'
            })
        } else if(e){
           handleError(e)
        }
        setAddItem(false)
       })
    }

    const renderTable = (item, index)=>{
        return(
            <div key={`entry-${index}`} style={{display: 'flex', justifyContent: 'space-evenly', margin: '15px 0px'}}>
                <div style={{width: '160px'}}>
                    {item.desc}<br/>
                    {props.data.poNumber?`Item ref no :${item.referenceId}`:null} {props.data.poNumber?<br/>:null}
                    {item.orderId?`PO Number:${item.orderId}`:null} <br/>
                    Code: {item.hsn} <br /> 
                    Service : {item.service} <br></br>
                    {item.glCode ? <>GL Code : {item.glCode} <br/></> : null}
                    {item.serviceDesc && (
                     `Desc : ${item.serviceDesc}`
                    )}
                    {item.notes ?<>Notes : {item.notes} <br></br></> :null}
                    <div style={{}}>
                    </div>
                    {
                        item.attrs && Object.keys(item.attrs).length > 0 &&
                            item.attrs["lineItemReferenceNo"] ?
                            <p>Line Item Reference No : {item.attrs["lineItemReferenceNo"]}</p> : <></>
                    }
                    {
                        item.attrs && Object.keys(item.attrs).length > 0 &&
                            item.attrs["SO Number"] ?
                            <p>SO No : {item.attrs["SO Number"]}</p> : <></>
                    }
                    {
                        item.attrs && Object.keys(item.attrs).length > 0 &&
                            item.attrs["seven-segment"] ?
                            <p>Seven-segment : {item.attrs["seven-segment"]}</p> : <></>
                    }
                    {item.forms && item.forms.length > 0 ? (
					<div>
				      <div 
					     style={{paddingTop:5,cursor: 'pointer'}}
					      onClick={() => setShowCustomFormId(showCustomFormId && showCustomFormId === item.id ? false : item.id)}>
						<h4>
						{showCustomFormId === item.id ? 'Hide': 'Show'}Custom form</h4>
				    	</div> <br />
					  {showCustomFormId === item.id &&
						item.forms.map((e) => (
						<div style={{ flexDirection: 'column' }} key={e.key}>
            	       	<p style={{fontWeight:250,color:'grey'}}> {e.key}: <span style={{fontWeight:500,color:'black'}}>{e.value}</span></p>
						 <br />
						</div>
						))}
					</div>
				    ) : null}
                    {item.serviceAttrs && _.size(item.serviceAttrs) > 0 ? (
                            <div>
                                <div className='mt2'
                                    style={{ fontSize: 13, fontWeight: '700', cursor: 'pointer' }}
                                    onClick={() => setShowServiceCustomFormId(showServiceCustomFormId && showServiceCustomFormId === item.id ? false : item.id)}>
                                    <p> {showServiceCustomFormId === item.id ? 'Hide' : "Show Service Tags"}</p>
                                </div>
                                <br />
                                {showServiceCustomFormId === item.id &&
                                    _.keys(item.serviceAttrs).map((e) => (
                                        <div style={{ flexDirection: 'column', marginTop: -10, textAlign: 'left' }} key={e}>
                                            <p style={{ fontWeight: 250, color: 'grey' }}> {e}: <span style={{ fontWeight: 500, color: 'black' }}>{item.serviceAttrs[e]}</span></p>
                                            <br />
                                        </div>
                                    ))}
                            </div>
                        ) : null}
                    </div>

                <div style={{width: '100px',}}> {item.price ?numberWithCommas(item.price.toFixed(2)):item.price}</div>
                <div style={{width: '100px',}}> {item.qtty}</div>
                <div style={{width: '100px', textAlign: 'right'}}> {item.amount ?numberWithCommas(item.amount.toFixed(2)):numberWithCommas(item.amount)} </div>
                <div style={{width: '100px', textAlign: 'right'}}>
               { invoiceStatus ==='APPROVAL' &&
                <span  style={{padding:5,color:'var(--primary-color)',cursor:'pointer'}}
                   onClick={() => setEdit(item)}>
                    EDIT
                </span>}
               {(invoiceStatus ==='APPROVAL' && enableLineItemPlugin)&&
                <span  style={{padding:5,color:'var(--red-color)',cursor:'pointer',}}
                   onClick={() => setRemove(item)}>
                    Remove
                </span>}
                </div>
            </div>
        )
    }



    return (
        <div className='FadeRight-Effect' style={{width: '100%', fontSize: '12px'}}>
            <div style={{textAlign: 'center', fontSize: '22px', fontWeight: 'bold'}}>INVOICE</div>

            <div style={{
                border: '1px solid var(--border-color)',
                padding: '20px',
                borderRadius: '15px',
                marginTop: '30px'
            }}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>
                        <b>Vendor</b><br/>
                        {props.data.vendor}<br/>
                        Email: {props.data.email}<br/>
                    </div>
                    <div style={{textAlign: 'right'}}>
                        Date: {props.data.date}<br/>
                        {props.data.due?<>Due Date: {moment(props.data.due).format('DD-MM-YYYY')}<br/></>:<></>}
                        Invoice ID: {props.data.Invoice_id}<br/>
                    {props.data.asn ? `ASN: ${props.data.asn.asnId}`: ''}<br/>
                    {props.data.asn ? `ASN Created On: ${moment(props.data.asn.createdAt).format('DD-MM-YYYY')}`: ''}<br/>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
                <div style={{paddingRight: '20px'}}>
                    <b>Billing Address</b><br/>
                    {props.data.bill_add.title?<span>{props.data.bill_add.title}<br></br></span>:null}
                    {props.data.bill_add.registeredName?<span>{props.data.bill_add.registeredName}<br></br></span>:null}
                </div>
                <div style={{textAlign: 'right', paddingLeft: '20px'}}>
                    <b>Shipping Address</b><br/>
                    {props.data.ship_add.title?<span>{props.data.ship_add.title}<br></br></span>:null}
                    {props.data.ship_add.registeredName?<span>{props.data.ship_add.registeredName}<br></br></span>:null}
                </div>
                </div>
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'space-evenly',
                marginTop: '30px',
                fontWeight: 'bold',
                paddingBottom: '10px',
                borderBottom: '1px solid var(--border-color)'
            }}>
                <div style={{width: '160px'}}>Desciption</div>
                <div style={{width: '100px',}}>Price</div>
                <div style={{width: '100px',}}>Qty</div>
                <div style={{width: '100px', textAlign: 'right'}}>Amount</div>
                <div style={{width: '100px', textAlign: 'right'}}>Actions</div>
            </div>

            {
                props.data.table.map(renderTable)
            }
            {enableAddLineItem && status === 'APPROVAL' ?
                <p
                    style={{
                        marginTop: 12,
                        color: "var(--primary-color)",
                        fontSize: 13,
                        cursor: "pointer",
                        fontWeight: 600,
                        marginLeft: 1
                    }}
                    onClick={() => {
                        setAddItem(true);
                    }}
                >
                    + Add Line Item
                </p>
                : null
            }
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>Base Amt:</div>
                    <div> {props.data.base ?(props.data.base).toFixed(2):props.data.base}</div>
                </div>
            </div>
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>Discount Amt:</div>
                    <div> {props.data.discount?(props.data.discount).toFixed(2):props.data.discount}</div>
                </div>
            </div>
            <div className='invoice-taxes' style={{fontWeight: 'bold'}}>
                <div className='wrap'>
                    <div>Tax Amt:</div>
                    <div> {props.data.tax}</div>
                </div>
            </div>
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>Total Amt:</div>
                    <div> {props.data.total ?(props.data.total).toFixed(2):props.data.total}</div>
                </div>
            </div>
            <div className='invoice-taxes'>
                <div className='wrap'>
                    <div>Base Currency Amt:</div>
                    <div> {props.data.bill_add?props.data.bill_add.currency:""} {props.data.baseCurrencyAmount ? numberWithCommas(props.data.baseCurrencyAmount):props.data.baseCurrencyAmount}</div>
                </div>
            </div>
            {(props && props.data && props.data.currencyExchangeRate)?<div className='invoice-taxes'>
                <div className='wrap'>
                    <div>Currency Exchange Rate:</div>
                    <div> {props.data.currencyExchangeRate}</div>
                </div>
            </div>:null}
            {edit && (
                <LineItemModal
                details={props.details}
                edit={edit}
                searchProductByService={searchProductByService}
                categoryId={props.data.categoryId}
                onSave={onSaveItem}
                tdsAtLineItem={props.tdsAtLineItem}
                tdsAtLineItemAutomation={(props && props.tdsAtLineItemAutomation)?props.tdsAtLineItemAutomation:undefined}
                tdsAtLineItemInContract={props.tdsAtLineItemInContract}
                vendorId={props.data.vendorId}
                close={() => setEdit(undefined)}
                category={props.data.category}
                contractId={props.data.contractId}
                disableUpdate={disableUpdate}
                tdsSlabs={(props && props.tdsSlabs)?props.tdsSlabs:undefined}
                vendor={(props && props.data && props.data.vendorDetails)?props.data.vendorDetails:undefined}
                />
            )}
            {addItem && (
                <AddLineItem
                    details={props.details}
                    categoryId={props.data.categoryId}
                    vendorId={props.data.vendorId}
                    onSubmit={onAddLineItem}
                    bill_to={props.data.bill_to.title || null}
                    onClose={() => setAddItem(undefined)} 
                    singleLine={props.data.singleLine}
                    tdsSlabs={(props && props.tdsSlabs) ? props.tdsSlabs : undefined}
                    vendor={(props && props.data && props.data.vendorDetails) ? props.data.vendorDetails : undefined}
                />
            )}
            {remove ?
                <Modal
                    buttonName="Remove"
                    onSave={() => {
                        post(`/vendor/invoicing/${systemInvoiceId}/invoiceItem.remove`, {
                            "item_id": remove.id
                        }, (e, r) => {
                            if (r) {
                                post(`vendor/costAllocation/removeAllocation`, { invoiceId: systemInvoiceId }, (e, r) => {
                                    if (r) {
                                        props.onReload()
                                    } else {
                                        props.onReload()
                                    }
                                })
                                showToast({
                                    type: 'success',
                                    message: 'Item Removed successfully'
                                })
                            } else if (e) {
                                handleError(e);
                            }
                            setRemove(null)
                        });

                    }}
                    title={"Remove Line Item"}
                    des=""
                    close={() => {
                        setRemove(null)
                    }}
                    style={{
                        save: {
                            width: '100%',
                            textAlign: 'center',
                            fontSize: 17,
                            background: "var(--red-color)",
                            border: "2px solid var(--red-color)",
                            padding: "12px 20px"
                        },
                    }}
                    show={remove}
                >
                    <div>Are you sure, you want to remove line item?</div>
                </Modal>
                : null}
        </div>
        
    )
}

export default Invoice;
