import React from "react";
import { numberWithCommas } from "../../../Utils/NumberToComma";

export function getRandomColor() {
    const colors = ['rgb(143 178 248)', 'rgb(218 195 253)', 'rgb(135 235 212)', 'rgb(233 197 107)', 'rgb(250 163 128)'];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
}

function TableContainer({ name, color }) {
    return (
        <div className="text-center flex" style={{ border: '1px solid #d0d0d0', padding: 3, borderRadius: 4 }}>
            <div style={{
                height: '23px',
                width: '3px',
                backgroundColor: color ? color : undefined,
                marginLeft: '1px',
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',

            }} />
            <p className="pd0" >{name}</p>
        </div>
    )
}

export default ({ checks }) => {
    let items = checks && checks.ocrLineItem ? [...checks.ocrLineItem] : []

    return (
        <table className='' style={{ tableLayout: 'auto',border:'1px solid rgb(208, 208, 208)' }} >
            <thead>
                <tr style={{}}>
                    {['Details', 'Price', 'Base', 'GrossAmount', 'Cess', 'Qty', 'Amount',].map((item, index) => {
                        return (
                            <th style={{ border: 'none', height: 'auto' }} key={index}>
                                <TableContainer name={item} />
                            </th>
                        )
                    })}

                </tr>

            </thead>
            <tbody style={{}}>
                {items && items.map((item, index) => {
                    return (
                        <tr key={item.id} style={{}}>
                            <td >{item.name || item.desc}</td>
                            <td>{numberWithCommas(parseFloat(item && item.price ? item.price : 0).toFixed(3)) || 0}</td>
                            <td>{numberWithCommas(parseFloat(item && item.base ? item.base : 0).toFixed(2)) || 0}</td>
                            <td>{numberWithCommas(parseFloat(item && item.grossBase ? item.grossBase : 0).toFixed(2)) || 0}</td>
                            <td>{numberWithCommas(item && item.cessAmount ? item.cessAmount.toFixed(2) : 0)}</td>
                            <td>{parseFloat(item && item.qtty ? item.qtty : 0).toFixed(3)}</td>
                            <td>{numberWithCommas(item && item.total ? item.total.toFixed(2) : 0)}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}